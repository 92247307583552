import React, {useState} from "react"

import Layout from "../../../components/Layout"
import SEO from "../../../components/SEO"
import "./index.scss"
import Spacer from "../../../components/Spacer"
import { graphql, Link } from "gatsby"
import Image from "../../../components/Image";
import Footer from "../../../components/Footer";
import Img from 'gatsby-image';
import {sortByKey, sortByOrder} from '../../../utils/sort';


function PersonBioCard({photo, job, fullName, bio, favoritesCasestudies: projects = []}) {
   let [showBio, toggleBio] = useState(false);

  return (
    <div className="PersonBioCard">
      <Img className="photo" fluid={photo.thumb.childImageSharp.fluid} alt={fullName} />
      <p className="fullName">{fullName}</p>
      <p className="job">{job}</p>
      <div className={`bio ${!showBio ? 'hide-on-mobile' : ''}`}>{bio}</div>
      <div className={`projects ${!showBio ? 'hide-on-mobile' : ''}`}>
        {
           projects.map((project, key) => (<Link key={key.toString()} to={`/work/${project.slug}`}>{project.title}</Link>))
        }
      </div>
      <div className="toggle-bio"
           onClick={() => toggleBio(!showBio)}>
         {!showBio ? 'Show Bio' : 'Hide Bio'}
      </div>
    </div>
  )
}


const StudioTeamBio = ({data}) => {
  let {teammembers} = data.cms;
  // Only active users, now work in company
  let people = teammembers.filter(member => member.active);
  let classNames = 'desktop-add-space tablet-add-space';

	return (
  <Layout Footer={<Footer/>}>
    <SEO title="Team Bio" keywords={[`gatsby`, `application`, `react`]}/>
    <div className={`teams-bio-content ${classNames}`}>
      { sortByKey(people, 'fullName').sort(sortByOrder).map((person, key) => <PersonBioCard key={key.toString()} {...person}/> ) }
      <Spacer/>
    </div>
  </Layout>
)};

export const query = graphql`
{
   cms {
      teammembers {
         id
         fullName
         job
         active
         bio
         order
         photo {
           url
           name
           ext
           thumb: localImage {
             childImageSharp {
               fluid(maxWidth: 3000, quality: 90) {
                 base64
                 aspectRatio
                 src
                 srcSet
                 sizes
               }
             }
           }
         }
         favoritesCasestudies {
          id
          title
          slug
        }
      }
   }
}
`;

export default StudioTeamBio
